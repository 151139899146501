:root {
  --blue: #0e0f26;
  --dark-blue: #04040e;
  --blue-link: #5971ff;
  --white: #fff;
  --pink: #ffa7bc;
  --dark-pink: #cf5874;
  --light-blue: #c5c2ec;
  --lighter-blue: #e5e3f9;
  --light-text: #c8c8c8;
  --lighter-text: #e9e9e9;
  --dark-text: #4e4e4e;
  --darker-text: #252525;
  --white: #fff;
  --orange: #ffc683;
  --dark-orange: #e4953a;
  --lavender: #cfc3f5;
  --light-lavender: #eee8ff;
  --lavender-dark: #baace2;
  --lavender-dark-text: #816dbe;
  --lavender-darker-text: #1f0d50;
  --lavender-nav: #d7c9ff;
  --black: #1e1e1e;
  --bright-blue: #def1fd;
  --deep-blue: #043c54;
  --deep-blue-text: #052837;
  --light-black: #e3e3e3;
  --blue-grad: linear-gradient(90deg, var(--blue) 10%, var(--light-blue) 200%);
}

body {
  scrollbar-width: thin;
  scrollbar-color: var(--orange) transparent;
  position: static;
}
.ci-container {
  padding: 0 40px;
}
.ci-section {
  padding-top: 50px;
  padding-bottom: 50px;
}
.ci-first-section {
  padding-top: 100.8px;
}
.ci-first-heading {
  padding-top: 40px;
}
/*Scrollbar*/
::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--orange);
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  border-radius: 10px;
}
/* Buttons */
.ci-solid-btn-pink {
  font-family: "Montserrat";
  background-color: var(--pink);
  color: (--blue);
  font-weight: 500;
  text-transform: uppercase;
  font-size: larger;
  border: solid 1px transparent;
  padding: 10px 15px;
  transition: all 0.1s;
}

.ci-solid-btn-orange {
  font-family: "Montserrat";
  background-color: var(--orange);
  color: (--black);
  font-weight: 500;
  text-transform: uppercase;
  font-size: larger;
  border: solid 1px transparent;
  padding: 10px 15px;
  transition: all 0.1s;
  font-weight: bold;
}
.ci-solid-btn-deepblue {
  font-family: "Montserrat";
  background-color: var(--deep-blue);
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
  font-size: larger;
  border: solid 1px transparent;
  padding: 10px 15px;
  transition: all 0.1s;
  font-weight: bold;
}
.ci-solid-btn-pink:hover {
  background-color: var(--white);
  color: var(--dark-pink);
  border: solid 1px var(--dark-pink);
}

.ci-solid-btn-orange:hover {
  background-color: var(--white);
  color: var(--dark-orange);
  border: solid 1px var(--dark-orange);
}
.ci-solid-btn-deepblue:hover {
  background-color: transparent;
  color: var(--deep-blue-text);
  border: solid 1px var(--deep-blue);
}
.ci-solid-btn-blue {
  font-family: "Montserrat";
  background-color: var(--blue);
  color: var(--white);
  font-weight: 500;
  text-transform: uppercase;
  font-size: larger;
  border: solid 1px transparent;
  padding: 10px 15px;
  transition: all 0.1s;
}
.ci-solid-btn-blue:hover {
  background-color: var(--white);
  color: var(--blue);
  border: solid 1px var(--blue);
}
.ci-solid-btn-underline {
  font-family: "Montserrat";
  background-color: transparent;
  font-weight: 500;
  text-transform: uppercase;
  font-size: small;
  border: none;
  border-bottom: solid 3px;
  padding: 10px 15px;
  transition: all 0.1s;
}
.ci-solid-btn-underline-blue {
  color: var(--black);
  border-color: var(--black);
}
.ci-solid-btn-underline-blue:hover {
  background-color: var(--orange);
  color: var(--black);
  border-color: var(--orange);
  font-weight: bold;
}
.ci-btn-wide {
  width: 50%;
}
.ci-btn-small {
  width: 200px;
}
.ci-whiteout {
  filter: saturate(0) brightness(10000%);
}
.ci-promo-blue {
  background: var(--blue);
  color: var(--white);
}
.ci-promo-pink {
  background: var(--pink);
  color: var(--blue);
}
.ci-promo-orange {
  background: var(--orange);
  color: var(--black);
}
.ci-promo-deepblue {
  background: var(--deep-blue);
  color: var(--white);
}
.ci-btn-full-light-blue {
  background: var(--lavender);
  color: var(--black);
  border: solid 1px transparent;
  width: 100%;
  padding: 12px 2px;
  font-weight: bold;
}
.ci-btn-full-light-blue:hover {
  background: var(--white);
  color: var(--lavender-dark-text);
  border: solid 1px var(--lavender-dark-text);
}
.ci-btn-full-blue {
  background: var(--deep-blue-text);
  color: var(--white);
  font-weight: bold;
  border: solid 1px transparent;
  width: 100%;
  padding: 12px 2px;
  margin: 15px 0;
}
.ci-btn-full-blue:hover {
  background: var(--white);
  color: var(--deep-blue-text);
  border: solid 1px var(--deep-blue-text);
}
/*Product*/
.ci-product-container {
  display: inline-block;
  border: solid 1px var(--light-text);
  transition: transform 0.4s;
  background-color: var(--white);
}
.ci-product-container:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.ci-product-inner-container {
  position: relative;
  width: 100%;
  height: 100%;
}
.ci-product-promo-container {
  position: absolute;

  font-weight: bold;
  padding: 6px;
  right: 0;
  top: 0;
}
.ci-product-info {
  display: flex;
  flex-direction: column;
  padding: 10px;
  align-items: center;
  overflow: hidden;
}
.ci-product-promo-text {
  padding: 0;
  margin: 0;
}
.ci-product-details {
  height: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  text-align: center;
}

.ci-product-name {
  font-weight: 500;
  margin: 10px 0 0;
  font-size: medium;
  width: 100%;
  white-space: nowrap;
}
.ci-product-info.overflowing .ci-product-name {
  transform: translateX(calc(-1 * (100vw - (100vw - 100%))));
  transition: transform 5s ease-in-out;
}
.ci-product-cost {
  margin: 10px 0 0;
  font-weight: bold;
  font-size: large;
}
.ci-product-button {
  margin: 10px 0 0;
}
.ci-product-info {
  color: var(--black);
  height: 100%;
  width: 255px;
}

.ci-product-image {
  height: 35vh;
  min-height: 200px;
  width: 100%;
  max-height: 256px;
}
.ci-product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/*Gallery*/
.ci-gallery {
  padding: 20px 0 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px 10px;
  justify-content: center;
}
.ci-gallery-select {
  width: 100px;
  border: none;
  color: var(--dark-blue);
  background: none;
  margin: 0 0 0 10px;
  text-align: center;
}
.ci-gallery .ci-gallery-product-container {
  flex-basis: calc(25%-6px);
  display: flex;
  justify-content: center;
}
.ci-gallery-filter-container {
  display: block;
  flex-basis: 100%;
}
.ci-gallery-filter-container-inner {
  display: inline-block;
  background: var(--orange);
  border-radius: 25px;
  padding: 10px;
}
.ci-gallery-filter-container-inner select {
  font-weight: bold;
}
.ci-gallery-filter-container-inner option {
  border: none !important;
  background: var(--lavender);
  outline: 0px;
}
.ci-gallery-pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  gap: 5px;
  padding: 0;
}
.ci-gallery-pagination .ci-gallery-page-num {
  padding: 8px 15px;
  cursor: pointer;
  border-radius: 3px;
  font-weight: bold;
  color: var(--deep-blue);
}
.ci-gallery-pagination .ci-gallery-page-num:hover {
  background-color: var(--lighter-text);
}
.ci-gallery-pagination .ci-gallery-page-active {
  background-color: var(--light-black);
  color: var(--deep-blue-text);
}
.ci-gallery-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 20px;
}
@media (max-width: 1130px) {
  .ci-gallery-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (max-width: 680px) {
  .ci-gallery-grid {
    grid-template-columns: 1fr 1fr;
  }
}
/* Navbar */
.ci-nav-container {
  background: rgba(255, 255, 255, 0.9);
  z-index: 5;
  position: fixed;
  width: 100vw;
  transition: all 0.2s;
}

.ci-nav-container.ci-not-over-banner {
  background: var(--lavender-nav) !important;
  color: var(--white) !important;
}
.ci-nav-logo,
.ci-nav-icon {
  display: flex;
  padding: 10px 0px;
}
.ci-nav-logo {
  align-items: center;
  justify-content: center;
}
.ci-nav-icon-r {
  align-items: center;
  justify-content: right;
}
.ci-nav-logo {
  text-align: center;
}
.ci-nav-logo-svg {
  max-width: 200px;
}
.ci-nav-icon-text {
  padding-right: 10px;
  font-weight: 500;
  font-size: large;
}
.ci-nav-icon-container {
  padding: 10px;
  border-radius: 50px;
  color: var(--black);
  background-color: var(--white);
  border: solid 2px var(--light-black);
  font-size: larger;
  display: flex;
  align-items: center;
  transition: all 0.2s;
}

.ci-nav-icon-container:hover {
  background-color: var(--lavender-dark-text);
  color: var(--white);
  font-weight: bolder !important;
  cursor: pointer;
  border-color: transparent;
}
.ci-nav-items ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  padding: 0;
  gap: 25px;
}
.ci-nav-items-container {
  display: flex;
  justify-content: center;
}

.ci-nav-item {
  color: var(--black);
  transition: transform 0.3s;
}
.ci-nav-item:hover {
  transform: scale(1.2);
}
.ci-nav-item-link {
  color: var(--black);
  border-bottom: solid 2px transparent;
}
.ci-nav-cart-icon {
  color: var(--orange);
}
.ci-nav-icon-container:hover .ci-nav-cart-icon {
  color: var(--white);
}
.ci-nav-item-link.active {
  border-bottom: solid 2px var(--orange);
}
.ci-nav-item:hover .ci-nav-item-link {
  color: var(--lavender-dark-text);
}
.ci-nav-search-input {
  width: 0;
  border: none;
  border-radius: 2px;
  color: var(--black);
  font-size: small;
  transition: all 0.3s;
  padding: 0;
  margin: 0;
  border-bottom: solid 2px var(--black);
  font-weight: bold;
}
.ci-nav-search-go {
  width: 0;
  border-radius: 13px;
  border: solid 2px transparent;
  background-color: var(--orange);
  color: var(--black);
  font-size: small;
  padding: 0;
  margin: 0;
  display: none;
  font-weight: bold;
}
.ci-nav-search-go:hover {
  border: solid 2px transparent;
  background-color: var(--white);
  color: var(--black);
  font-weight: bold;
}
.ci-nav-search-go.nav-click-go {
  display: unset;
  display: inline;
  width: unset;
  padding: 3px 4px;
}

.ci-nav-search-button-mobile {
  padding: 6px;
  background: var(--black);
  color: white;
  border-radius: 10px;
  position: relative;
  top: -1px;
}
.ci-nav-search-button-mobile:hover {
  cursor: pointer;
}
.ci-not-over-banner .ci-nav-search-button-mobile {
  padding: 6px;
  background: white;
  color: var(--black);
  border-radius: 10px;
  position: relative;
  top: -1px;
}
.nav-click-show {
  display: inline;
  width: 160px;
  padding: 2px;
  margin: 0 10px;
}
.ci-search-clicker {
  position: absolute;
  top: 9px;
  left: 39px;
  z-index: 6;
  width: 42px;
  height: 51px;
  border-radius: 26px;
}
.ci-nav-mobile-menu-item-link {
  border-bottom: solid 2px transparent;
}
.ci-nav-mobile-menu-item-link.active {
  border-bottom: solid 2px var(--orange);
}
.ci-nav-container.ci-not-over-banner .ci-nav-item-link,
.ci-nav-container.ci-not-over-banner .ci-nav-mobile-menu-item-link {
  color: var(--black);
  border-bottom: solid 2px transparent;
}
.ci-nav-container.ci-not-over-banner .ci-nav-item-link.active,
.ci-nav-container.ci-not-over-banner .ci-nav-mobile-menu-item-link.active {
  color: var(--black);
  border-bottom: solid 2px var(--dark-orange);
}
.ci-nav-container.ci-not-over-banner .ci-nav-cart-icon {
  color: var(--dark-orange);
}
.ci-nav-container.ci-not-over-banner .ci-nav-icon-container {
  background-color: var(--white);
  color: var(--lavender-dark-text);
  border-color: var(--lavender-dark);
}
.ci-nav-container.ci-not-over-banner .ci-nav-icon-container:hover {
  background-color: var(--lavender-dark-text);
  color: var(--white);
}
.ci-nav-container.ci-not-over-banner
  .ci-nav-icon-container:hover
  .ci-nav-cart-icon {
  color: var(--white);
}
.ci-nav-container.ci-not-over-banner .ci-nav-mobile-search-flex {
  color: var(--white);
}
.ci-nav-container.ci-not-over-banner .ci-nav-search-go {
  background: var(--lavender);
}
.ci-nav-container.ci-not-over-banner .ci-nav-search-go:hover {
  background: var(--white);
  color: var(--lavender-dark-text);
}
.ci-nav-logo-svg:hover {
  cursor: pointer;
}
.ci-nav-cart-notification {
  position: relative;
}
.ci-nav-cart-notification p {
  display: inline;
  position: absolute;
  font-size: small;
  background: crimson;
  color: white;
  font-weight: bold;
  right: -16px;
  top: 3px;
  padding: 3px 9px;
  border-radius: 100px;
}
/*Banner*/
.ci-banner-container {
  height: 100vh;
  min-height: 600px;
  background-color: var(--lavender);
}
.ci-banner-container-row {
  display: flex !important;
  height: 100%;
}
.ci-banner-container-col {
  height: 100%;
  position: relative;
}
.ci-banner-container-col img {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
  transition: all 0.2s;
  object-fit: cover;
  height: 100%;
  object-position: -18px 0px;
}
.ci-banner-col-opp img {
  left: 0;
  right: unset;
  object-position: -109px 0px;
}
.ci-banner-maintitle {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 84px;
  margin: 0;
  padding: 0;
  color: #fff;
  text-shadow: 0px 2px 2px rgb(88 88 88 / 52%);
}
.ci-banner-subtitle {
  font-family: "Playfair Display", serif;
  color: var(--black);
  font-weight: bold;
  font-size: 62px;
  margin-bottom: 0px;
  text-transform: uppercase;
  bottom: 20px;
  position: relative;
}
.ci-banner-col-l {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.ci-banner-btn {
  align-self: center;
}

/*Trending*/

.ci-trending-heading {
  color: var(--black);
  position: relative;
}
.ci-trending-heading::after {
  content: "";
  position: absolute;
  height: 6px;
  width: 100px;
  top: 50px;
  left: 135px;
  background-color: var(--orange);
}
.ci-trending-button-container {
  display: flex;
  justify-content: center;
}
.ci-trending-gallery {
  padding: 20px 0 35px;
  gap: 30px;
}
.ci-trending-gallery .slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ci-trending-gallery .slick-dots {
  position: relative;
  padding-top: 8px;
}
.ci-trending-gallery .slick-dots li.slick-active button:before {
  color: var(--dark-orange) !important;
}
.ci-trending-gallery .slick-prev,
.ci-trending-gallery .slick-next {
  z-index: 3;
}
.ci-trending-gallery .slick-prev:before,
.ci-trending-gallery .slick-next:before {
  color: var(--dark-orange) !important;
  font-size: 22px !important;
}

/*Newest Additions*/
.ci-new-container {
  background-color: var(--bright-blue);
}
.ci-new-heading {
  color: var(--deep-blue-text);
}
.ci-new-button-container {
  display: flex;
  justify-content: center;
}
.ci-new-showcase {
  padding: 20px 0 35px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 20px 0;
}
.ci-new-showcase .ci-new-product-container {
  flex-basis: 33.33%;
  display: flex;
  justify-content: center;
}
.ci-new-gallery {
  padding: 20px 0 35px;
  gap: 30px;
}
.ci-new-gallery .slick-slide > div {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ci-new-gallery .slick-dots {
  position: relative;
  padding-top: 8px;
}
.ci-new-gallery .slick-dots li.slick-active button:before {
  color: var(--deep-blue-text) !important;
}
.ci-new-gallery .slick-dots li button:before {
  color: var(--deep-blue-text) !important;
}
.ci-new-gallery .slick-prev,
.ci-new-gallery .slick-next {
  z-index: 3;
}
.ci-new-gallery .slick-prev:before,
.ci-new-gallery .slick-next:before {
  color: var(--deep-blue-text) !important;
  font-size: 22px !important;
}

/* Our Product */
.ci-ourproduct-container {
  background-color: var(--bright-blue);
  padding-bottom: 0 !important;
  padding-top: 100px;
}
.ci-ourproduct-heading {
  text-align: right;
  color: var(--deep-blue-text);
  padding-bottom: 30px;
}
.ci-ourproduct-description {
  color: var(--deep-blue-text);
  text-align: right;
  font-size: large;
}
.ci-ourproduct-inner-container {
  display: flex;
  flex-direction: row-reverse;
}
.ci-ourproduct-info {
  width: 500px;
  padding-right: 40px;
}
.ci-ourproduct-image-container {
  position: relative;
  flex-grow: 1;
}
.ci-ourproduct-image {
  position: absolute;
  bottom: -2px;
  left: -11px;
  width: 520px;
}
/*Footer*/
.ci-footer-container {
  background-color: var(--deep-blue-text);
  padding-bottom: 20px;
}
.ci-footer-inner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.ci-footer-socials-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  flex-grow: 1;
}
.ci-footer-socials-container i {
  color: var(--white);
  font-size: x-large;
  padding-bottom: 20px;
}
.ci-footer-links-container {
  width: 88vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
}
.ci-footer-links-container a,
.ci-footer-links-container p {
  color: var(--white);
}
/*Products Screen*/
.ci-products-screen-container {
  min-height: 90vh;
}
.ci-products-screen-heading {
  padding-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.ci-products-screen-heading h2 {
  display: inline;
  color: var(--black);
}
.ci-products-search {
  display: inline;
  font-weight: bold;
}
.ci-products-search-icon {
  margin-left: 10px;
  color: var(--deep-blue-text);
}
/*Media Screen*/
.ci-media-screen-heading {
  padding-top: 40px;
  color: var(--black);
}

.ci-media-screen-container .pswp-gallery {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 10px;
  padding: 30px 0;
  min-height: 95vh;
}

.ci-media-screen-container .pswp-gallery > a {
  height: 300px;
  position: relative;
  flex-grow: 1;
}

.ci-media-screen-container .pswp-gallery a img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  vertical-align: middle;
  border-radius: 5px;
}
.ci-media-screen-container .pswp-gallery::after {
  content: "";
  flex-grow: 999;
  min-width: 200px;
}
/*Contact*/
.ci-contact-screen-container {
  padding-bottom: 40px;
  min-height: 95vh;
}
.ci-contact-screen-container .ci-contact-col {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ci-contact-screen-heading {
  color: var(--black);
  text-align: center;
}
.ci-contact-info p {
  font-size: x-large;
}
.ci-contact-icons-container i {
  color: var(--black);
  font-size: xx-large;
  padding: 10px;
}
.ci-contact-icons-container i:hover {
  color: var(--lavender-dark-text);
}
.ci-contact-feedback form {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  font-size: larger;
}
.ci-contact-feedback form input,
.ci-contact-feedback form textarea {
  width: 100%;
}
.ci-contact-feedback button,
.ci-contact-call-btn {
  font-size: medium !important;
}
/*Single Item*/
.ci-si-screen-container {
  padding-bottom: 40px;
  padding-top: 140px;
  min-height: 95vh;
}

/* .ci-si-screen .ci-nav-container {
  background: var(--light-blue) !important;
  color: var(--black) !important;
}
.ci-si-screen .ci-nav-icon-container:hover {
  background-color: var(--light-blue) !important;
  color: var(--black) !important;
  border-color: var(--blue) !important;
  font-weight: bolder !important;
}
.ci-si-screen .ci-whiteout {
  filter: unset;
}
.ci-si-screen .ci-nav-item-link {
  color: var(--black) !important;
  border-bottom: solid 2px transparent;
}
.ci-si-screen .ci-nav-container.ci-not-over-banner .ci-nav-icon-container {
  background-color: var(--blue);
  color: var(--light-blue);
  border: solid 1px transparent;
}
 */
.ci-si-product-name {
  color: var(--black);
  font-size: 2.6em;
}
.ci-si-product-heading {
  color: var(--black);
  font-family: "Montserrat";
  font-size: xx-large;
  margin-top: 60px;
}
.ci-product-review-container {
  background-color: var(--bright-blue);
  border-radius: 20px;
  padding: 20px 16px;
  margin-bottom: 20px;
}
.ci-si-review-name {
  color: var(--black);
  font-weight: bold;
  font-size: medium;
}
.ci-si-review-comment {
  color: var(--black);
  font-weight: 500;
  font-size: large;
}
.ci-si-review-rating-container i {
  color: var(--orange);
  font-size: large;
}
.ci-si-product-old-price {
  text-decoration: line-through;
  color: var(--light-text);
  font-size: larger;
  margin: 0;
}
.ci-si-product-price {
  color: var(--black);
  font-weight: bold;
  font-size: xx-large;
}
.ci-si-product-quantity {
  font-weight: 500;
  font-size: large;
  color: var(--black);
}
.ci-si-product-quantity select {
  margin-left: 15px;
  background-color: var(--lighter-text);
  padding: 8px;
  border: none;
}
.ci-si-product-instock {
  font-weight: 500;
  font-size: large;
  color: var(--lavender-dark-text);
}
.ci-si-product-notinstock {
  font-weight: 500;
  font-size: larger;
  color: var(--dark-pink);
}
.ci-si-product-sub-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
}
.ci-si-product-sub-container img {
  object-fit: cover;
  width: 80px;
  height: 80px;
}
.ci-si-product-sub-container img:hover {
  cursor: pointer;
}
.ci-si-selected {
  border: solid 2px var(--lavender-dark-text);
}
.ci-si-product-main {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
  padding: 20px;
}
.ci-si-product-main img {
  height: 100%;
  object-fit: cover;
  max-width: 100%;
}
.ci-si-bot-info {
  display: flex;
  flex-direction: column;
  height: 230px;
  justify-content: flex-end;
}
.ci-si-submit-review-container {
  color: var(--black);
  background-color: var(--white);
  border: solid 1px var(--black);
  border-radius: 12px;
  padding: 20px;
  max-width: 40%;
  margin: auto;
}
.ci-si-submit-review-container form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px 0;
  font-size: larger;
}
.ci-si-submit-review-container form button {
  width: 60%;
  align-self: center;
}
.ci-si-submit-review-name,
.ci-si-submit-review-rating,
.ci-si-submit-review-comment {
  width: 100%;
  margin-top: 20px;
  font-size: large;
}
.ci-si-submit-review-label-heading {
  font-size: x-large;
  font-weight: bold;
  text-align: center;
  margin: 0;
}
.ci-si-submit-review-label-heading:hover {
  cursor: pointer;
}
.ci-si-submit-review-container form {
  display: none;
}
.ci-si-submit-review-container form.ci-si-review-active {
  display: flex;
  height: unset;
}
.ci-si-submit-review-comment-heading {
  text-align: start;
}
.ci-media-screen-container .pswp-gallery {
  width: 100%;
  height: 100%;
  text-align: center;
}
.ci-si-product-main .pswp-gallery {
  height: 100%;
}
.ci-si-review-delete-button {
  color: var(--black) !important;
  background-color: transparent;
  float: right;
  padding: 4px;
  font-size: x-large;
}
.ci-si-review-delete-button:hover {
  cursor: pointer;
}
/*Cart*/
.ci-cart-screen-heading {
  padding-top: 140px;
}
.ci-cart-screen-container {
  padding-top: 10px;
  min-height: 70vh;
}

.ci-cart-screen-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 20px;
}
.ci-cart-screen-item-container {
  padding: 10px 0;
  background-color: var(--light-lavender);
  border-radius: 20px;
  margin-bottom: 20px;
}
.ci-cart-item-cell {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
.ci-cart-screen-item-name {
  padding: 0 10px;
}
.ci-cart-screen-item-total {
  align-self: center;
}
.ci-cart-screen-item-remove-btn,
.ci-cart-screen-item-view-item-btn {
  border: solid 1px transparent;
  padding: 5px 20px;
  width: 80%;
  margin-bottom: 10px;
  transition: all 0.3s;
}
.ci-cart-screen-item-remove-btn {
  background: var(--lavender-dark);
  color: var(--white);
}
.ci-cart-screen-item-view-item-btn {
  background: var(--white);
  color: var(--black);
  border-color: transparent;
}
.ci-cart-screen-item-remove-btn:hover {
  border: solid 1px var(--lavender-dark-text);
  color: var(--lavender-dark-text);
  background: var(--white);
  font-weight: bold;
}

.ci-cart-screen-item-view-item-btn:hover {
  border: solid 1px transparent;
  color: var(--white);
  background: var(--black);
  font-weight: bold;
}
.ci-cart-screen-total-cost-container {
  margin-bottom: 30px;
}
.ci-cart-screen-total-cost {
  font-size: larger;
  font-weight: 600px;
}
.ci-cart-screen-checkout-btn {
  border: solid 1px transparent;
  background: var(--deep-blue);
  color: var(--white);
  padding: 10px 20px;
  transition: all 0.3s;
  width: 80%;
}
.ci-cart-screen-checkout-btn:hover {
  background: var(--orange);
  font-weight: bold;
  color: var(--deep-blue-text);
  transform: scale(1.1);
  padding: 10px 20px;
}
/*Checkout*/
.ci-order-payment-form-container {
  min-height: 40vh;
  border: solid 1px var(--light-text);
  margin: 20px auto 40px;
  border-radius: 15px;
  width: 60%;
}
.ci-order-screen-heading {
  padding-top: 40px;
}
.ci-order-summary-container {
  margin: 20px 0;
}
.ci-order-summary-container table {
  width: 100%;
  background: var(--light-lavender);
  border-radius: 10px;
}
.ci-order-summary-container td,
.ci-order-summary-container th {
  padding: 5px;
  text-align: center;
}
.ci-order-customer-information {
  padding: 40px 50px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}
.ci-order-customer-information form {
  display: flex;
  flex-direction: column;
  text-align: start;
  gap: 10px;
}
.ci-order-customer-information form input {
  padding: 5px;
}
.ci-order-form-btn {
  width: calc(95% - 20px);
  margin-left: 10px;
  margin-right: 10px;
  border: solid 1px transparent;
  padding: 10px 0;
  transition: all 0.3s;
}
.ci-order-form-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ci-order-form-btn-paypal {
  background: var(--blue-link);
  color: var(--white);
  margin: 20px 0;
  font-weight: bold;
  transition: all 0.3s;
  margin-bottom: 0;
}
.ci-order-form-btn-visa {
  background: var(--orange);
  color: var(--black);
  margin: 20px 0;
  font-weight: bold;
  transition: all 0.3s;
}
.ci-order-form-btn-paypal:hover,
.ci-order-form-btn-visa:hover {
  background: var(--white);
  color: var(--deep-blue-text);
  border-color: var(--deep-blue-text);
  font-weight: bold;
  transform: scale(1.1);
}
/*Tracking*/
.ci-tracking-screen-heading {
  padding-top: 140px;
}
.ci-tracking-screen-container {
  padding-top: 10px;
  min-height: 90vh;
}
.ci-tracking-status {
  margin: 20px 0;
  text-align: center;
}
.ci-tracking-delivered {
  padding: 10px;
  background-color: var(--lavender-dark-text);
  color: var(--white);
  border-radius: 10px;
  display: block;
  width: 50%;
  margin: auto;
}
.ci-tracking-undelivered {
  padding: 10px;
  background-color: crimson;
  color: var(--white);
  border-radius: 10px;
  display: block;
  width: 50%;
  margin: auto;
}
.ci-tracking-preparing {
  padding: 10px;
  background-color: rgb(238, 179, 63);
  color: var(--white);
  border-radius: 10px;
  display: block;
  width: 50%;
  margin: auto;
}
.ci-tracking-delivery {
  padding: 10px;
  background-color: rgb(54, 122, 239);
  color: var(--white);
  border-radius: 10px;
  display: block;
  width: 50%;
  margin: auto;
}

.ci-orders-preparing {
  background-color: rgb(255, 225, 165) !important;
}
.ci-orders-preparing-in-person {
  background-color: rgb(255, 225, 165) !important;
  border: 1px solid #ff7900;
}
.ci-orders-delivery {
  background-color: rgb(154, 191, 255) !important;
}
.ci-orders-unsuccessful {
  background-color: rgb(255, 161, 161) !important;
}
.ci-orders-delivered {
  background-color: var(--lavender) !important;
}
.ci-orders-inperson {
  background-color: rgb(203, 255, 163) !important;
}
.ci-tracking-screen-tray {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  width: 100%;
  padding: 20px;
}
/*Not Found*/
.ci-not-found-screen-container {
  padding-bottom: 40px;
  min-height: 95vh;
}
.ci-not-found-screen-heading {
  margin: 30px 0;
}
.ci-not-found-screen-card {
  width: 60%;
  display: flex;
  flex-direction: column;
  margin: 20px auto;
  align-items: center;
}
.not-found-icon {
  color: var(--lavender-dark);
  font-size: 10em;
}
.ci-not-found-screen-card a {
  margin: 30px 0;
}
/* Fields */
.field-error {
  border: 2px solid var(--bs-red);
}
