/*Mobile Display Config*/
.mobile-show {
  display: none;
}
/*Medium devices (desktops, 992px and down)*/
@media (max-width: 992px) {
  .first-section-mobile {
    padding-top: 70.8px;
  }
  /*Mobile Display Config*/
  .mobile-hidden {
    display: none;
  }
  .mobile-show {
    display: block;
  }
  /*Product*/
  .ci-product-info {
    width: 200px;
    max-width: 200px;
  }
  .ci-product-name {
    font-size: 0.8em;
    width: 100%;
  }
  .ci-product-details {
    height: 124px;
  }

  /*Navbar*/
  .ci-nav-cart-notification p {
    right: -13px;
    top: 3px;
    padding: 3px 9px;
    border-radius: 100px;
  }
  .ci-nav-container {
    padding: 11px 10px;
    background: var(--white);
  }
  .ci-nav-icon-container {
    padding: 10px;
    border-radius: 10px;
    color: var(--black);
    background: none;
    font-size: xx-large;
    align-items: center;
    border: none;
  }
  .ci-nav-container.ci-not-over-banner .ci-nav-icon-container {
    background: transparent;
    color: var(--white);
  }
  .ci-nav-container.ci-not-over-banner .ci-nav-cart-icon {
    color: var(--white);
  }
  .ci-nav-icon-container.mobile-menu-clicked {
    color: var(--black);
    background: var(--light-black);
  }
  .ci-nav-container.ci-not-over-banner
    .ci-nav-icon-container.mobile-menu-clicked {
    color: var(--black);
    background: var(--white);
  }
  .ci-nav-items-mobile-menu {
    list-style: none;
    padding: 0;
    transition: all 0.1s;
    overflow: hidden;
  }
  .ci-nav-items-mobile-menu ul {
    padding: 0;
    text-align: center;
  }
  .ci-nav-mobile-menu-item {
    margin-top: 10px;
    font-size: larger;
  }
  .ci-nav-items-mobile-menu .ci-nav-mobile-menu-item-link {
    color: var(--black);
  }
  .mobile-menu-open {
    height: 260px;
  }
  .mobile-menu-close {
    height: 0;
  }
  .ci-nav-search-mobile {
    border-radius: 6px;
    border: solid 1px var(--black);
    color: var(--black);
    font-size: large;
    padding: 0;
    margin: 0;
    text-align: center;
  }

  .ci-nav-mobile-search-flex {
    display: none;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
    position: absolute;
    color: var(--black);
    font-weight: 500;
    padding: 2px 6px;
    width: unset;
  }
  .mobile-search-show {
    display: inline-flex;
  }

  /*Banner*/

  .ci-banner-container {
    height: 100vh;
  }
  .ci-banner-col-l {
    height: 40% !important;
  }
  .ci-banner-col-r {
    height: 60% !important;
  }
  .ci-banner-container-col img {
    right: 6%;
    width: 88%;
  }
  .ci-banner-container-col img {
    height: unset;
    object-fit: cover;
    object-position: 0px 0px;
  }
  .ci-banner-text-container {
    text-align: center;
  }
  /*Our Product*/
  .ci-ourproduct-container {
    padding-top: 30px;
  }
  .ci-ourproduct-heading {
    text-align: center;
    color: var(--deep-blue-text);
    padding-bottom: 25px;
  }
  .ci-ourproduct-description {
    color: var(--deep-blue-text);
    text-align: center;
  }
  .ci-ourproduct-inner-container {
    display: flex;
    flex-direction: column;
  }
  .ci-ourproduct-info {
    width: 100%;
    padding: 0 20px;
  }
  .ci-ourproduct-image-container {
    position: relative;
    flex-grow: 1;
  }
  .ci-ourproduct-image {
    position: relative;
    bottom: -2px;
    left: -11px;
    width: 349px;
  }
}
/*Small devices (tablets, 768px and down)*/
@media (max-width: 767px) {
  .first-section-mobile {
    padding-top: 70.8px;
  }
  .ci-container {
    padding-left: 20px;
    padding-right: 20px;
  }
  /*Mobile Display Config*/
  .mobile-hidden {
    display: none;
  }
  .mobile-show {
    display: block;
  }
  /*Product*/
  .ci-product-image {
    height: 25vh;
    min-height: 200px;
  }
  .ci-product-info {
    width: 200px;
  }
  .ci-product-name {
    font-size: 0.8em;
    width: 100%;
  }
  .ci-product-details {
    height: 124px;
  }
  /*Banner*/
  .ci-banner-container {
    height: 94vh;
  }
  .ci-banner-col-l {
    height: 50% !important;
  }
  .ci-banner-col-r {
    height: 50% !important;
  }
  .ci-banner-maintitle {
    font-size: 44px;
    margin: 0;
    padding: 10px 0 0;
  }
  .ci-banner-subtitle {
    font-size: 36px;
    margin-bottom: 20px;
    bottom: 12px;
  }
  .ci-banner-container-col img {
    right: 3%;
    width: 94%;
  }
  /*Trending*/
  .ci-trending-heading {
    text-align: center;
  }
  .ci-trending-heading::after {
    left: 47%;
  }
  /*New*/
  .ci-new-heading {
    text-align: center;
  }
  /*Footer*/
  .ci-footer-container {
    padding-top: 40px;
    padding-bottom: 15px;
  }
  .ci-footer-inner-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .ci-footer-socials-container {
    display: block;
    padding-bottom: 20px;
  }
  .ci-footer-socials-container i {
    font-size: x-large;
    padding-bottom: unset;
    padding: 0 10px;
  }
  .ci-footer-links-container {
    align-items: center;
  }
  .ci-footer-links-container a,
  .ci-footer-links-container p {
    color: var(--white);
  }
  /*Items*/
  .ci-products-screen-heading {
    text-align: center;
  }
  .ci-products-screen-container .ci-product-info {
    width: 40vw;
    min-width: 130px;
  }
  .ci-products-screen-container .ci-product-name {
    font-size: 0.68em;
    width: 100%;
  }
  /*Gallery*/
  .ci-gallery-filter-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    text-align: center;
  }
  .ci-gallery-select {
    width: 50px;
  }
  /*Products*/
  .ci-products-screen-heading {
    padding-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .ci-products-search-container {
    padding-top: 10px;
  }
  /*Media*/
  .ci-media-screen-heading {
    text-align: center;
  }
  .ci-media-screen-container .pswp-gallery > a {
    height: 100px;
    position: relative;
    flex-grow: 1;
  }
  /*Single Product*/
  /* .ci-si-screen .ci-nav-container.ci-not-over-banner .ci-nav-icon-container {
    background-color: var(--light-blue);
    color: var(--blue);
  }
  .ci-si-screen
    .ci-nav-container.ci-not-over-banner
    .ci-nav-icon-container.mobile-menu-clicked {
    background-color: var(--blue);
    color: var(--light-blue);
  }
  .ci-si-screen
    .ci-nav-container.ci-not-over-banner
    .ci-nav-mobile-menu-item-link {
    color: var(--blue);
  }
  .ci-si-screen
    .ci-nav-container.ci-not-over-banner
    .ci-nav-mobile-search-flex {
    color: var(--blue);
  } */
  .ci-si-product-main {
    padding: 20px 0;
  }
  .ci-si-bot-info {
    display: block;
    height: unset;
  }
  .ci-si-product-main {
    height: 300px;
  }
  .ci-si-product-name {
    font-size: xx-large;
    text-align: center;
    margin-top: 30px;
  }
  .ci-si-product-old-price {
    margin-top: 20px;
    display: inline-block;
  }
  .ci-si-product-price {
    margin-top: 20px;
    float: right;
    display: inline;
  }
  .ci-si-product-quantity {
    display: block;
  }
  .ci-si-product-heading {
    text-align: center;
    margin: 30px 0;
  }

  .ci-si-product-notinstock {
    margin-top: 20px;
    float: left;
    display: inline;
    position: relative;
    top: 10px;
  }
  .ci-si-product-main img {
    width: 100%;
  }
  .ci-si-submit-review-comment-heading {
    text-align: center;
  }
  .ci-si-submit-review-container {
    max-width: 100%;
  }
  /*Cart*/
  .ci-cart-screen-item-image {
    padding: 15px;
    border-radius: 30px;
  }
  .ci-cart-screen-container {
    padding: 10px;
    min-height: 60vh;
  }
  .ci-cart-screen-heading {
    padding-left: 10px;
  }
  .ci-cart-screen-item-remove-btn,
  .ci-cart-screen-item-view-item-btn {
    font-size: small;
  }
  .ci-cart-screen-checkout-btn {
    font-size: small;
  }
  /*Checkout*/
  .ci-order-customer-information {
    width: 100%;
    padding: 25px 14px;
  }
  .ci-order-payment-form-container {
    width: 100%;
  }
  /*Order Tracking*/
  .ci-tracking-undelivered {
    width: 100%;
    margin: 0;
  }
  .ci-tracking-delivered {
    width: 100%;
    margin: 0;
  }
  .ci-tracking-screen-tray {
    padding: 0px;
  }
  .ci-not-found-screen-card {
    width: 95%;
  }
}
/*Smaller devices */
@media (max-width: 325px) {
  .first-section-mobile {
    padding-top: 130.8px;
  }
  /*Mobile Display Config*/
  .mobile-hidden {
    display: none;
  }
  .mobile-show {
    display: block;
  }
  .ci-product-image {
    height: 15vh;
  }
  .ci-product-details {
    height: 140px;
  }
  .ci-product-info {
    width: 200px;
  }
}
/*Responsiveness*/
@media (min-height: 600px) and (min-width: 600px) and (max-height: 992px) and (max-width: 992px) {
  .first-section-mobile {
    padding-top: 180.8px;
  }
  /*Mobile Display Config*/
  .mobile-hidden {
    display: none;
  }
  .mobile-show {
    display: block;
  }
  /*Banner*/

  .ci-banner-container {
    height: 100vh;
    min-height: 800px;
  }
  .ci-banner-container-col img {
    right: 30%;
    width: 40%;
  }
}

@media (min-height: 200px) and (min-width: 500px) and (max-height: 600px) and (max-width: 992px) {
  .first-section-mobile {
    padding-top: 180.8px;
  }
  /*Mobile Display Config*/
  .mobile-hidden {
    display: none;
  }
  .mobile-show {
    display: block;
  }
  /*Banner*/

  .ci-banner-container {
    height: 100vh;
    min-height: 800px;
  }
  .ci-banner-container-col img {
    right: 30%;
    width: 40%;
  }
}
@media (max-width: 380px) {
  .ci-trending-heading::after {
    display: none;
  }
}
