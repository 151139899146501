/* Main Admin Screen*/
.login-container {
  background-color: var(--lavender);
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*Login Card*/
.ci-card {
  border-radius: 0px !important;
}
/*Admin header*/
.ci-logout {
  color: var(--white) !important;
  font-weight: bold !important;
}
.ci-admin-nav {
  background: var(--lavender-nav);
}
.ci-login-screen .ci-admin-nav {
  background-color: var(--white);
}
.ci-nav-admin-link {
  background: var(--lavender-dark-text);
  color: var(--white);
  font-weight: 500;
  padding: 5px;
  border-radius: 20px;
  font-size: small;
}
.ci-nav-admin-link:hover {
  background: var(--white);
  color: var(--lavender-dark-text);
}
/* Sidebar */
.navbar-aside {
  max-width: 260px;
  display: block;
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  overflow-y: auto;
  background-color: #fff;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.075);
  z-index: 3;
  border-right: 1px solid rgba(108, 117, 125, 0.25);
  transition: left 0.3s ease-in-out;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.navbar-aside::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navbar-aside {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.navbar-aside .aside-top {
  padding: 1rem 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.navbar-aside.show {
  left: 0;
}

.navbar-aside.hide {
  left: -300px;
}
.menu-aside {
  list-style: none;
  margin: 0.5rem;
  padding: 0;
}
.menu-aside a {
  display: block;
  text-decoration: none;
}

.menu-aside .menu-item {
  margin-bottom: 5px;
}
.menu-aside .menu-item .icon {
  color: #aeaeae;
  margin-right: 10px;
  font-size: 18px;
}
.menu-aside .active .icon {
  color: var(--lavender-dark-text);
}
.menu-aside .active {
  background-color: #ebf0fd;
}
.menu-aside .menu-link {
  padding: 10px;
  color: #29335d;
  font-weight: bold;
  border-radius: 0.3rem;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.menu-aside .menu-link .text {
  vertical-align: middle;
}
.menu-aside .menu-link:hover {
  transition: 0.2s linear;
  background-color: #e9ecef;
}
.menu-aside .submenu {
  margin-left: 44px;
  display: none;
}
.menu-aside .submenu a {
  color: #6c757d;
  padding: 5px;
}
.menu-aside .submenu a:hover {
  color: #000;
}
.menu-aside .menu-item.active .submenu {
  display: block;
}
.menu-aside .menu-item.has-submenu > .menu-link:after {
  display: block;
  float: right;
  position: absolute;
  right: 10px;
  top: 10px;
  margin-top: 0.6em;
  content: "";
  border-top: 5px solid #adb5bd;
  border-right: 5px solid transparent;
  border-bottom: 0;
  border-left: 5px solid transparent;
}
.sidebar-container {
  margin-top: 134px;
}
.mobile-block {
  display: none;
}

.btn-aside-show {
  display: none;
  position: fixed;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 40px;
  z-index: 2;
}
.btn-aside-show:hover {
  color: white;
}
.aside-show-icon {
  padding: 2px 13px 2px 0;
  color: white;
}
.aside-btn-menu-text {
  margin: 0;
}
.btn-aside-hide {
  display: none;
}
/* Interface Split */
.sidebar-out-container {
  width: 260px;
  flex-shrink: 0;
}
.interface-container {
  padding-top: 120px;
  display: flex;
  flex-direction: row;
}
.main-display {
  flex-grow: 1;
}

.jewel-admin-side-btn {
  background-color: var(--orange);
  border-color: transparent;
  text-shadow: none;
  font-weight: bold;
  color: var(--white);
  padding: 8px;
  font-size: large;
  margin-top: 30px;
}
.admin-search-container {
  width: 100%;
  text-align: center;
}
.admin-items-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

/*Admin Small devices (tablets, 768px and down)*/
@media (max-width: 700px) {
  .admin-header {
    text-align: center;
  }
  .btn-aside-show {
    display: flex;
  }
  .btn-aside-hide {
    display: flex;
  }
  .main-display {
    width: 100vw;
    padding-top: 80px;
  }
  .interface-container {
    flex-direction: column;
  }
  .admin-header {
    text-align: center;
  }
  .card {
    border: none !important;
    box-shadow: none !important;
  }
  .admin-top-initial-container {
    text-align: center;
  }
  form .jewel-btn-orange {
    margin: 0 auto;
    font-size: larger;
    width: 50%;
    padding: 10px 20px;
    font-weight: bold;
  }
}
@media (min-width: 701px) {
  .navbar-aside.hide {
    left: 0px;
  }
}
@media (max-width: 983px) {
  .admin-items-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}

/*Restock*/
.admin-card-wrap-restock {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-restock-button {
  padding: 10px 30px;
}
/* Admin Items */
.admin-main-container {
  margin: 30px 30px;
}
.admin-header {
  margin: 0px 0 20px;
}
.admin-main-container .card-info-container {
  color: var(--white);
  background: var(--orange);
}
.admin-items-searchbar {
  margin: 20px 0;
  text-align: center;
  padding: 5px;
  font-size: large;
}
.content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-items-container .card-info-container {
  color: var(--white);
  background: var(--orange);
}

.admin-items-card {
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 300px;
  min-width: 300px;
  margin: 20px;
}
/*Flexy Stuff*/
@media (max-width: 600px) {
  .admin-items-card {
    flex-basis: calc(80% + 40px);
    min-width: unset;
  }
}
.admin-items-card:last-child {
  align-self: flex-start;
}

.buttons-edit-icon-container {
  float: right;
  padding-left: 5px;
}
.admin-edit-icon {
  margin-bottom: 10px;
  font-size: larger;
}
.admin-pencil-icon {
  color: var(--lavender-dark-text);
}
.admin-delete-icon {
  color: crimson;
}
/* Edit Item Details*/
.admin-header-backlink {
  float: right;
  display: inline;
}
.admin-inline {
  display: inline;
}
.admin-form-heading {
  font-size: x-large;
  font-weight: bold;
}
.admin-plus {
  color: white;
  background-color: var(--orange);
  border: none;
}

.admin-times {
  color: var(--orange);
  background-color: transparent;
  border: none;
}
.previewImage {
  display: flex;
  align-items: center;
  justify-content: center;
}
/* dashboard */
.main-graph-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.admin-dashboard-bottom-container {
  height: 60px;
  padding-left: 30px;
}
.admin-main-chart {
  height: 500px;
  width: 500px;
  margin: 30px;
}
.top-cards .card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin: 0 30px;
  border: none;
  box-shadow: 2px 2px 4px rgb(167, 167, 167);
}
.admin-card-content {
  font-size: 64px;
  font-weight: bold;
}
@media (max-width: 700px) {
  .admin-main-chart {
    height: 280px;
    width: 280px;
    max-width: 80vw;
    max-height: 80vw;
    margin: 20px 0px;
  }
  .admin-dashboard-bottom-container {
    text-align: center;
    margin-top: 20px;
    padding: 0px;
  }
}
.imPreview {
  height: 250px;
  max-width: 300px;
}
@media (max-width: 700px) {
  .imPreview {
    height: 200px;
    max-width: 250px;
  }
}
/*Upload button*/
.imageUploadContainer {
  background-color: var(--lavender-dark-text);
  display: flex;
  align-items: center;
  border: solid 1px #ced4da;
  border-radius: 0.25rem;
  overflow: hidden;
}
.imageUploadButton {
  border: none;
  color: white;
  padding: 6px 8px;
  background-color: transparent;
  height: 100%;
  line-height: unset;
}
.imageUploadText {
  border: none;
  padding: 0;
  width: 100%;
  padding: 6px 12px;
}

/*Form CSS Custom*/
.adminImagePreview {
  max-height: 50vh;
  max-width: 100%;
  object-fit: cover;
}
.admin-prodbtn-container {
  width: 100%;
  display: flex;
}
.admin-add-button {
  margin: 0 auto;
  width: 40%;
}
@media (max-width: 700px) {
  .admin-add-button {
    width: 100%;
  }
}
.control-image-remove {
  color: crimson;
  margin-left: 10px;
  font-size: 20px;
  cursor: pointer;
}
.control-image-element {
  width: 100%;
  height: 400px;
  object-fit: cover;
}
.control-image-container {
  display: flex;
  overflow: hidden;
  margin: 20px auto;
}
.control-images-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
@media (max-width: 551px) {
  .control-images-container {
    display: grid;
    grid-template-columns: 1fr;
  }
}
.control-videos-heading {
  margin-top: 70px;
}
.control-vid-container {
  width: fit-content;
  padding: 10px 20px;
  border-radius: 20px;
  margin: 10px 0;
  border: solid 1px var(--lavender-dark-text);
}

.admin-orders-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.admin-order-container {
  padding: 1rem;
  background-color: var(--lavender);
  margin-bottom: 10px;
  border-radius: 18px;
  width: 60%;
}
.admin-order-inner-top {
  border-bottom: solid 1px var(--lavender-dark-text);
}
.admin-order-name {
  font-weight: bold;
  display: inline-block;
  margin: 0;
  font-size: large;
  color: var(--lavender-darker-text);
}
.admin-order-id {
  display: inline;
  font-weight: normal;
  margin: 0;
  font-size: smaller;
  color: var(--lavender-dark-text);
  float: right;
}
.admin-order-inner-bottom {
  display: flex;
  width: 100%;
}
.admin-orders-container table {
  width: 100%;
  font-size: small;
  margin: 10px auto 0;
}
.admin-orders-container table td {
  text-align: right;
}
.admin-order-tab button {
  border: none;
  background: none;
}
.admin-order-tabs-wrapper {
  background-color: var(--lavender-nav);
  margin-top: 20px;
}
.admin-order-tabs-container {
  display: flex;
  flex-direction: row;
  padding: 10px 10px 0;
}
.admin-order-tab {
  border-radius: 5px 5px 0 0;
  margin-left: 5px;
  background-color: var(--lavender-dark-text);
}
.admin-order-tab.admin-active {
  background-color: var(--white);
}
.admin-order-notification {
  display: none;
}
.admin-order-notification.admin-orders-active {
  display: inline;
  background-color: hotpink;
  color: var(--white);
  border-radius: 45px;
  padding: 2px 8px;
  font-size: smaller;
  float: right;
}
.admin-order-details-wrap {
  padding: 1em;
  border: solid 1px var(--lighter-text);
  border-radius: 18px;
}
.admin-order-details-table {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.admin-order-details-table-items {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.admin-order-details-row {
  display: flex;
  flex-direction: row;
}
.admin-order-details-h-cell {
  font-weight: bold;
  width: 50%;
  text-align: right;
  padding-right: 2em;
}
.admin-order-details-table-items .admin-order-details-cell {
  flex-basis: 20%;
  text-align: center;
}
.admin-order-delete-button-container {
  width: 100%;
  text-align: right;
  margin: 10px 0;
}
.admin-order-delete-button {
  padding: 10px;
  border: solid 2px var(--lavender-dark-text);
  border-radius: 10px;
  cursor: pointer;
  background-color: var(--white);
  color: var(--lavender-dark-text);
  transition: all 0.3s;
}
.admin-order-delete-button:hover {
  color: white;
  background-color: var(--lavender-dark-text);
  border: solid 2px transparent;
}
@media (max-width: 551px) {
  .admin-order-id {
    display: block;
    padding-left: 0;
  }
  .admin-order-container {
    width: 100%;
  }
  .admin-order-details-h-cell {
    font-weight: bold;
    width: 31%;
    text-align: right;
    padding-right: 2em;
  }
  .admin-order-details-h-cell p,
  .admin-order-details-cell p,
  .admin-order-details-cell a {
    font-size: 11px !important;
  }
}
