/* Structural CSS */
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea,
input,
select {
  outline: none;
}

html,
body {
  overflow-x: hidden;
}

p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
}

h1 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 60px;
}

h2 {
  font-family: "Playfair Display", sans-serif;
  font-weight: bold;
  font-size: 40px;
}

h3 {
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  font-size: 20px;
  text-transform: capitalize;
}
a {
  text-decoration: none;
}
/*Bootstrap*/
.row {
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
}
/*React-Slick*/
.slick-slider,
.slick-list,
.slick-track,
.slick-slide,
.slick-slide > div {
  height: 100%;
}
.slick-list {
  overflow: unset !important;
}
